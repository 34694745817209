<template>
  <b-container class="overflow-auto" fluid>
    <!-- Ad Interface controls -->
    <h5 class="my-3"><strong>REMOTE VIDEO ZONES</strong></h5>
    <a-row class="my-2" :gutter="[8]">
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Advertiser" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedAdvertiser" @change="getFilteredZones">
          <a-select-option v-for="obj in advertiser.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Account Manager" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedUser" @change="getFilteredZones">
          <a-select-option v-for="obj in user.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.FirstName }} {{ obj.LastName
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-remote-video-zones'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-remotevideozones-columns'" :options="fields"></columnSelect>
          <CreateButton module="display" target="remotevideozone" />
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :sort-compare="sortCompare" :bordered="true" :current-page="currentPage" :fields="filteredFields"
      :filter="filter" :hover="true" :items="showRemoteVideoZones" :per-page="perPage" :small="true"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :striped="true"
      :tbody-tr-class="rowClass" show-empty stacked="md" @filtered="onFiltered">
      <!-- <template #cell(Name)="data">
        <div class="col col-md-12 d-flex justify-content-center justify-content-md-start">{{ data.item.Name }}</div>
      </template> -->
      <template #cell(Name)="data">
        <div class="items-center">
          {{ data.item.Name }}
        </div>
      </template>
      <template #cell(AdvertiserName)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div class="items-center">
            <div v-if="data.item.AdvertiserName.length <= 20">{{ data.item.AdvertiserName }}</div>
            <div class="text-left" style="max-width: 200px; min-width: 140px; word-wrap: break-word;"
              v-if="data.item.AdvertiserName.length > 20">{{ data.item.AdvertiserName }}</div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- name slot -->
          <div class="items-center">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.AdvertiserName" class=""
              v-if="data.item.AdvertiserName.length < 20">{{ data.item.AdvertiserName }}</div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.AdvertiserName" class=""
              v-if="data.item.AdvertiserName.length >= 20">{{ data.item.AdvertiserName.substring(0, 20) + ".." }}</div>
          </div>
        </div>
      </template>
      <template #cell(Rtbzones)="data">
        <div v-if="data.item.Rtbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in rtbzones">
                  {{
                    data.item.Rtbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Rtbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Videozones)="data">
        <div v-if="data.item.Videozones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in videozones">
                  {{
                    data.item.Videozones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Videozones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Pubfeeds)="data">
        <div class="col col-md-12 d-flex justify-content-center justify-content-md-start">{{ data.item.Pubfeeds }}</div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleRemoteVideoZoneStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop
          :to="{ name: 'display-remotevideozone-update', params: { id: data.item.Id }, }">
          <b-button class="btn-info mr-1" size="sm" @click="editRemoteVideoZone(data.item.Id)"><i
              class="fa fa-edit"></i></b-button>
        </router-link>
        <b-button class="btn-info mr-1" size="sm" @click="copyRemoteVideoZone(data.item.Id, data.item)"><i
            class="fa fa-copy"></i></b-button>
      </template>
    </b-table>

    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
          (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
            perPage).toLocaleString()
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import { cloneRemoteVideoZone } from '@/api/display/remotevideozone'
import { getRTBZonesShort } from '@/api/display/rtbzone'
import { getVideozonesShort } from '@/api/display/videozone'

export default {
  components: {
    columnSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      selectedAdvertiser: [],
      selectedUser: [],
      days: [
        {
          title: 'Saturday',
          shortcut: 'sat',
        },
        {
          title: 'Sunday',
          shortcut: 'sun',
        },
        {
          title: 'Monday',
          shortcut: 'mon',
        },
      ],
      selectedDays: ['sun'],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'Zone ID',
          show: true,
          headerTitle: 'Zone ID',
          sortable: true,
          thStyle: 'width:80px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Zone Name',
          show: true,
          headerTitle: 'Zone Name',
          sortable: true,
        },
        {
          key: 'AdvertiserName',
          label: 'Advertiser',
          show: true,
          headerTitle: 'Advertiser Name',
          sortable: true,
        },
        {
          key: 'Videozones',
          label: 'Video Zones',
          show: true,
          headerTitle: 'Video Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 105px',
        },
        {
          key: 'Rtbzones',
          label: 'RTB Zones',
          show: true,
          headerTitle: 'RTB Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 90px',
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          headerTitle: 'Bids Daily Limit',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ClicksDailyLimit',
          label: 'Clicks Daily Limit',
          show: true,
          headerTitle: 'Clicks Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'ZoneUrl',
          label: 'Zone URL',
          show: false,
          headerTitle: 'Zone URL',
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: true,
          headerTitle: 'Description',
          sortable: true,
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      rtbzones: null,
      videozones: null,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['remotevideozone', 'advertiser', 'settings', 'user']),
    ...mapGetters('remotevideozone', ['getRemoteVideoZones', 'getActiveRemoteVideoZones']),
    showActive: {
      get: function () {
        return this.settings.showActiveRemoteVideoZones
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveRemoteVideoZones', value })
      },
    },
    showRemoteVideoZones() {
      return this.showActive ? this.getActiveRemoteVideoZones : this.getRemoteVideoZones
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showRemoteVideoZones.length
    },
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === 'Rtbzones' || key === 'Videozones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },

    actionGetRemoteVideoZones() {
      this.$store.dispatch('remotevideozone/LOAD_ZONES_FOR_ADVERTISER', { AdvertiserId: this.selectedAdvertiser })
    },
    getFilteredZones() {
      if (this.selectedAdvertiser !== []) {
        window.localStorage.setItem('remote-video-zone-advertiser-key', JSON.stringify(this.selectedAdvertiser))
      }
      if (this.selectedUser !== []) {
        window.localStorage.setItem('remote-video-zone-user-key', JSON.stringify(this.selectedUser))
      }
      this.$store.dispatch('remotevideozone/LOAD_ZONES_FOR_USER_ADV', { AccManagerId: this.selectedUser, AdvertiserId: this.selectedAdvertiser })
    },
    getRemoteVideoZone(id) {
      cloneRemoteVideoZone(id).then(response => {
        if (response) {
          // this.$router.push('/display/remotevideozone/update/' + response.Remotevideozone.Id)
          this.$router.push({ name: 'display-remotevideozone-update', params: { id: response.Remotevideozone.Id } }).catch(() => { })
          this.$notification.success({
            message: 'Remote Video Zone cloned',
            description: `Remote Video Zone: ${response.Remotevideozone.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    copyRemoteVideoZone(id, ad) {
      Modal.confirm({
        title: 'Are you sure you want to clone Remote Video Zone?',
        content: h => <div>{status} Clone Remote Video Zone: <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          this.getRemoteVideoZone(id)
        }.bind(this),
      })
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editRemoteVideoZone(id) {
      if (this.selectedAdvertiser && this.selectedAdvertiser !== []) {
        window.localStorage.setItem('remote-video-zone-advertiser-key', JSON.stringify(this.selectedAdvertiser))
        if (window.localStorage.getItem('remote-video-zone-user-key')) {
          window.localStorage.setItem('remote-video-zone-user-key', JSON.stringify(this.selectedUser))
        }
      }
      // this.$router.push('/display/remotevideozone/update/' + id)
      this.$router.push({ name: 'display-remotevideozone-update', params: { id: id } }).catch(() => { })
    },
    toggleRemoteVideoZoneStatus(remotevideozone) {
      const status = remotevideozone.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change remote Video Zone status?',
        content: h => <div>{status} Remote Video Zone <strong>{remotevideozone.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = remotevideozone.Status === 0 ? 1 : 0
          this.$store.dispatch('remotevideozone/CHANGE_STATUS', {
            id: remotevideozone.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  // created() {
  //   if ((window.localStorage.getItem('remote-video-zone-advertiser-key')) !== null || window.localStorage.getItem('remote-video-zone-user-key') !== null) {
  //     this.$store.dispatch('remotevideozone/LOAD_ZONES_FOR_USER_ADV', { AdvertiserId: parseInt(window.localStorage.getItem('remote-video-zone-advertiser-key')) || [], AccManagerId: parseInt(window.localStorage.getItem('remote-video-zone-user-key')) || [] })
  //   } else {
  //     this.$store.dispatch('remotevideozone/LOAD_ZONES')
  //   }
  //   this.$store.dispatch('advertiser/LOAD_ADVERTISERS')
  //   this.$store.dispatch('user/LOAD_USERS')
  //     .then(() => {
  //       if (window.localStorage.getItem('remote-video-zone-advertiser-key')) {
  //         const filterPublisher = parseInt(window.localStorage.getItem('remote-video-zone-advertiser-key'))
  //         if (filterPublisher !== [] && !isNaN(filterPublisher)) {
  //           this.selectedPublisher = filterPublisher
  //         }
  //         window.localStorage.removeItem('remote-video-zone-advertiser-key')
  //       }
  //       if (window.localStorage.getItem('remote-video-zone-user-key')) {
  //         const filterUser = parseInt(window.localStorage.getItem('remote-video-zone-user-key'))
  //         if (filterUser !== []) {
  //           this.selectedUser = filterUser
  //         }
  //         window.localStorage.removeItem('remote-video-zone-user-key')
  //       }
  //     })
  // },
  created() {
    if ((window.localStorage.getItem('remote-video-zone-advertiser-key')) !== null || window.localStorage.getItem('remote-video-zone-user-key') !== null) {
      this.$store.dispatch('remotevideozone/LOAD_ZONES_FOR_USER_ADV', { AdvertiserId: parseInt(window.localStorage.getItem('remote-video-zone-advertiser-key')) || [], AccManagerId: parseInt(window.localStorage.getItem('remote-video-zone-user-key')) || [] })
    } else {
      this.$store.dispatch('remotevideozone/LOAD_ZONES')
    }
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS_SHORT')
    this.$store.dispatch('user/LOAD_USERS')
      .then(() => {
        if (window.localStorage.getItem('remote-video-zone-advertiser-key')) {
          const filterAdvertiser = parseInt(window.localStorage.getItem('remote-video-zone-advertiser-key'))
          if (filterAdvertiser !== [] && !isNaN(filterAdvertiser)) {
            this.selectedAdvertiser = filterAdvertiser
          }
          window.localStorage.removeItem('remote-video-zone-advertiser-key')
        }
        if (window.localStorage.getItem('remote-video-zone-user-key')) {
          const filterUser = parseInt(window.localStorage.getItem('remote-video-zone-user-key'))
          if (filterUser !== [] && filterUser) {
            this.selectedUser = filterUser
          }
          window.localStorage.removeItem('remote-video-zone-user-key')
        }
      })
    getRTBZonesShort()
      .then(res => {
        this.rtbzones = res
      })
    getVideozonesShort()
      .then(res => {
        this.videozones = res
      })
  },
  mounted() {
  },
}
</script>
